import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Pearl extends Component {
  constructor() {
    super();
    this.setDrag = this.setDrag.bind(this);
  }

  setDrag(e) {
    const { pageX, pageY } = e;
    const { drag, index } = this.props;
    drag(index, pageX, pageY );
  }

  render() {
    const { x, y } = this.props;
    const style = {
      top: y,
      left: x,
    };

    return (
      <div
        className="Pearl"
        style={style}
        onMouseDown={this.setDrag}
      />
    );
  }
}

Pearl.propTypes = {
  drag: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export default Pearl;
