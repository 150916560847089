import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import List from '../List/List';
import Stories from '../Stories/Stories';
import Projects from '../Projects/Projects';

import testComponent from './testComponent.module.scss';

const TestComponent = () => (
  <div className="Main">
    <List title="Stories">
      <Stories />
    </List>
    <List title="Projects">
      <Projects />
    </List>
  </div>
);

TestComponent.propTypes = {

};
TestComponent.defaultProps = {

};

export default TestComponent;
