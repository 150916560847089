import React, { Component } from 'react';

const StockMarket = () => {
  const url = 'https://ltse.com';
  const visitExchange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.location = url;
  };

  return (
    <a className="in-progress" href={url} onClick={visitExchange}>In Progress...</a>
  );
};

export default StockMarket;
