import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Spark extends Component {
  constructor() {
    super();
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    const { delay } = this.props;
    this.updatesToSkip = delay;
    // this.updateFunction = () => window.setInterval(this.update, 15000);
  }

  shouldComponentUpdate() {
    // Only update at most once every 20 updates
    this.updatesToSkip--;
    if (this.updatesToSkip <= 0) {
      this.updatesToSkip = 20;
      return true;
    }

    return false;
  }

  componentWillUnmount() {
  //   window.clearInterval(this.updateFunction);
  }

  update() {
    const { lastX, lastY } = this.props;
    this.setState({ x: lastX, y: lastY });
  }

  render() {
    const { lastX, lastY, delay } = this.props;
    const styles = {
      top: lastY,
      left: lastX,
    };
    return (
      <div className="Spark" style={styles} />
    );
  }
}

Spark.propTypes = {
  delay: PropTypes.number.isRequired,
  lastX: PropTypes.number,
  lastY: PropTypes.number,
};

Spark.defaultProps = {
  lastX: -100000,
  lastY: -100000,
};

export default Spark;
