import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Story from '../Story/Story';

class Stories extends Component {
  sinkStory = 'Back in my day we didn’t have a sink.  Instead we just had a beach ball under the faucet, and when you’d turn the faucet on the water would run down over the beach ball and splash everywhere.  It was especially hard to wash dishes, because you couldn’t soak anything and you had to hold the dish with one hand or it would slide off the beach ball and break!  We went through a lot of soup bowls back then.  One day Trudy was trying to get some giblets off a giant cleaver that had dried on and she got so frustrated that she raised the knife above her head and swung it down, cutting that beach ball right in half.  The halves fell apart and one of them came to rest right under the faucet, catching the water.  And that’s how we invented a sink.';
  towelStory = 'Now we had a sink, but we didn’t know about towels yet.  We would wash our hands and then just walk around with our hands dripping wet and they would get all chapped and red and cracked.  It was terrible!  Then one day little Billy washed his hands and then petted our dog Ralph and he noticed that his hands were dry!  No more chapping!  So from then on, we would wash our hands and called “Hey, RalphRalphRalphRalph!” and Ralph would run over and we would pet him and then our hands were dry. Ralph was an old dog, though, and one day Ralph died.  We propped him up next to the sink and kept petting his dead and bloating body each time we washed our hands, but after a couple of days the stench got to be too much and we had to bury him out back next to the still.  So then we were back to walking around with dripping wet hands, chapping and peeling and being sad about Ralph.  Then a couple weeks later my wife, Gertrude, was taking down the laundry after washing her hands and one of my flannel pajamas reminded her of Ralph.  She started crying and clutched the fuzzy pants to her chest, but when she finally put them down she noticed that her hands were dry!  So she stuffed the pants up, sewed on some eyes and propped the new flannel Ralph who would never die up next to the sink.  Eventually we rubbed right through that set of pants and next time Gertrude said if we wanted eyes we’d have to sew them on ourselves, so we just hung the pants up next to the sink and that’s how towels were invented.';
  writingStory = 'Just use periods whenever you think you should use a comma.';
  googleStory = 'I think that is a brilliant feature, because it lets the user be brave.  It takes guts to click that button!  You clench your guts, click the button and if it works you feel like a rockstar!  How many times a day does the average person get to feel like that?';
  planUpFrontStory = 'The student went to the teacher and asked, “should I think through an idea first or try it out?”  The teacher asked, “Which do you think is better?”  The student considered before replying,  “I think I should think through the ideas first.”  The teacher smiled and said, “why don’t you try that out?”';
  speedometerStory = 'I don’t like the phrase “moving the needle”.  You move the needle and then you say “now we’re going 120 miles an hour!” Nooooo.  You are still going exactly as fast as you were going, but now your speedometer is broken.';

  stories = [
    {
      title: 'How To Write Better',
      story: this.writingStory,
    },
    {
      title: 'Moving The Needle',
      story: this.speedometerStory,
    },
    {
      title: 'How The Sink Was Invented',
      story: this.sinkStory,
    },
    {
      title: 'How The Towel Was Invented',
      story: this.towelStory,
    },
    {
      title: "Google's 'I Feel Lucky' Button",
      story: this.googleStory,
    },
    {
      title: 'Should We Plan Before We Start?',
      story: this.planUpFrontStory,
    },
  ];

  constructor() {
    super();
    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {currentIndex: 0};
  }

  goForward() {
    const { currentIndex } = this.state;
    if (currentIndex >= this.stories.length - 1) {
      return;
    }

    this.setState({currentIndex: currentIndex + 1});
  }

  goBack() {
    const { currentIndex } = this.state;
    if (currentIndex <= 0) {
      return;
    }

    this.setState({currentIndex: currentIndex - 1})
  }

  render() {
    const { currentIndex } = this.state;
    const story = this.stories[currentIndex] || this.stories[0] || {};

    return (
      <div className="Stories">
        <div className="carousel">
          <button className="back" type="button" disabled={currentIndex <= 0} onClick={this.goBack} />
          <Story
            title={story.title}
            content={story.story}
          />
          <button className="forward" type="button" disabled={currentIndex >= this.stories.length - 1} onClick={this.goForward} />
        </div>
      </div>
    );
  }
}

Stories.propTypes = {

};
Stories.defaultProps = {

};

export default Stories;
