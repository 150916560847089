import React, { Component } from 'react';
import Spark from './Spark';

class Bidibidobidiboo extends Component {
  constructor() {
    super();
    this.spawnSparks = this.spawnSparks.bind(this);
    this.stopSparks = this.stopSparks.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.state = { drawing: false, lastX: -10000, lastY: -10000 };
  }

  onMouseMove(e) {
    const { pageX, pageY } = e;

    const currentTargetRect = e.currentTarget.getBoundingClientRect();
    this.setState({ lastX: pageX - currentTargetRect.left, lastY: pageY - currentTargetRect.top });
  }

  spawnSparks() {
    this.setState({ drawing: true });
  }

  stopSparks() {
    this.setState({ drawing: false });
  }

  render() {
    const { drawing, lastX, lastY } = this.state;
    return (
      <div className="projectArea sparkles" onMouseMove={this.onMouseMove} onMouseEnter={this.spawnSparks} onMouseLeave={this.stopSparks}>
        {drawing && (<Spark delay={1} lastX={lastX} lastY={lastY} />)}
        {drawing && (<Spark delay={5} lastX={lastX} lastY={lastY} />)}
        {drawing && (<Spark delay={10} lastX={lastX} lastY={lastY} />)}
        {drawing && (<Spark delay={15} lastX={lastX} lastY={lastY} />)}
        {drawing && (<Spark delay={20} lastX={lastX} lastY={lastY} />)}
      </div>
    );
  }
}

export default Bidibidobidiboo;
