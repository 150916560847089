import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bidibidobidiboo from '../Bidibidobidiboo/Bidibidobidiboo';
import Rope from '../Rope/Rope';
import StockMarket from '../StockMarket/StockMarket';

class Projects extends Component {
  projects = [
    {
      title: 'Rope',
      project: (<Rope />),
    },
    {
      title: 'Bidibidobidiboo',
      project: (<Bidibidobidiboo/>)
    },
    {
      title: 'Stock Market',
      project: (<StockMarket/>),
    },
  ];

  constructor() {
    super();
    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {currentIndex: 0};
  }

  goForward() {
    const { currentIndex } = this.state;
    if (currentIndex >= this.projects.length - 1) {
      return;
    }

    this.setState({currentIndex: currentIndex + 1});
  }

  goBack() {
    const { currentIndex } = this.state;
    if (currentIndex <= 0) {
      return;
    }

    this.setState({currentIndex: currentIndex - 1})
  }

  render() {
    const { currentIndex } = this.state;
    const project = this.projects[currentIndex] || this.projects[0] || {};

    return (
      <div className="Projects">
        <div className="carousel">
          <button className="back" type="button" disabled={currentIndex <= 0} onClick={this.goBack} />
          <div className="Project">
            <h5 className="title">
              {project.title}
            </h5>
            <div className="display">
              {project.project}
            </div>
          </div>
          <button className="forward" type="button" disabled={currentIndex >= this.projects.length - 1} onClick={this.goForward} />
        </div>
      </div>
    );
  }
}

Projects.propTypes = {

};
Projects.defaultProps = {

};

export default Projects;
