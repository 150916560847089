import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Content extends Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = { expanded: false };
  }

  toggle() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { content } = this.props;
    return (
      <div className="Content">
        {content}
      </div>
    );
  }
}

Content.propTypes = {
  content: PropTypes.node.isRequired,
};
Content.defaultProps = {

};

export default Content;
