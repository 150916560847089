import React, { Component } from 'react';
import Pearl from './Pearl';

const NUMBER_OF_PEARLS = 100;

function nearbyMoved(ourPosition, theirPosition) {
  const theirX = theirPosition.x;
  const theirY = theirPosition.y;

  const { x, y } = ourPosition;

  const deltaX = x - theirX;
  const deltaY = y - theirY;
  const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  if (Math.abs(distance) < 5) {
    return false;
  }

  const moveX = distance === 0 ? deltaX : 5 * deltaX / distance;
  const moveY = distance === 0 ? deltaY : 5 * deltaY / distance;

  const nextX = theirX + moveX;
  const nextY = theirY + moveY;

  return { x: nextX, y: nextY };
}

class Rope extends Component {
  constructor() {
    super();
    this.setDrag = this.setDrag.bind(this);
    this.move = this.move.bind(this);
    this.startDragging = this.startDragging.bind(this);
    this.stopDragging = this.stopDragging.bind(this);

    const positions = [];
    for (let i = 0; i < NUMBER_OF_PEARLS; i++) {
      positions.push({
        x: 0,
        y: 0,
      });
    }

    this.state = {
      dragging: false,
      positions,
    };
  }

  setDrag(pearlIndex, lastX, lastY) {
    this.setState({
      dragIndex: pearlIndex,
      dragging: true,
      lastX,
      lastY,
    });
  }

  move(e) {
    // calculate new X/Y
    const {
      dragIndex,
      lastX,
      lastY,
      positions,
    } = this.state;
    const { x, y } = positions[dragIndex];

    const { pageX, pageY } = e;
    const deltaX = pageX - lastX;
    const deltaY = pageY - lastY;

    const newX = x + deltaX;
    const newY = y + deltaY;

    const newPosition = { x: newX, y: newY };
    positions[dragIndex] = newPosition;

    // walk backwards
    let index = dragIndex - 1;
    let walkBackwards = newPosition;
    while (index >= 0 && walkBackwards) {
      walkBackwards = nearbyMoved(positions[index], walkBackwards);
      if (walkBackwards) {
        positions[index] = walkBackwards;
      }
      index--;
    }

    // walk forward
    index = dragIndex + 1;
    let walkForwards = newPosition;
    while (index < NUMBER_OF_PEARLS && walkForwards) {
      walkForwards = nearbyMoved(positions[index], walkForwards);
      if (walkForwards) {
        positions[index] = walkForwards;
      }
      index++;
    }

    // set index
    this.setState({
      lastX: pageX,
      lastY: pageY,
      positions,
    });
  }

  buildPearl(positions) {
    const pearls = [];
    for (let i = 0; i < NUMBER_OF_PEARLS; i++) {
      const position = positions[i];
      const nextPearl = (
        <Pearl
          index={i}
          key={`pearl_${i}`}
          drag={this.setDrag}
          x={position.x}
          y={position.y}
        />
      );

      pearls.push(nextPearl);
    }
    return pearls;
  }


  startDragging(e) {
    const { pageX, pageY } = e;
    this.setState({ dragging: true, lastX: pageX, lastY: pageY });
  }

  stopDragging() {
    this.setState({ dragging: false });
  }

  render() {
    const { dragging, positions } = this.state;
    const pearls = this.buildPearl(positions);
    const onMove = dragging ? this.move : null;

    return (
      <div
        className="projectArea Rope"
        onMouseMove={onMove}
        onMouseUp={this.stopDragging}
      >
        {pearls}
      </div>
    );
  }
}

export default Rope;
