import React, { Component } from 'react';
import PropTypes from 'prop-types';

const Modal = ({ title, children, toggleFunction }) => {
  const doNothing = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className="modal" onClick={toggleFunction}>
      <div className="modal-content" onClick={doNothing}>
        <h3>{title}</h3>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  toggleFunction: PropTypes.func.isRequired,
};

class List extends Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = { expanded: false };
  }

  toggle() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { title, children } = this.props;
    const { expanded } = this.state;

    return (
      <div className="List">
        <button type="button" onClick={this.toggle}>
          { expanded }
          <h3>{title}</h3>
        </button>
        {expanded && (
          <Modal title={title} toggleFunction={this.toggle}>
            {children}
            <div className="more" />
          </Modal>
        )}
      </div>
    );
  }
}

List.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

List.defaultProps = {
};

export default List;
