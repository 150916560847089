import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Content from '../Content/Content';

class Story extends Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = { expanded: false };
  }

  toggle() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { title, content } = this.props;

    return (
      <div className="Story">
        <h5 className="title">
          {title}
        </h5>
        <Content content={content} />
      </div>
    );
  }
}

Story.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};
Story.defaultProps = {

};

export default Story;
